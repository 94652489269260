import React from "react"
import Loadable from "@loadable/component"
import '../../assets/css/Common/client-feedback.css'

const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 1
        },
        992: {
            items: 2
        }
    }
}

const ClientFeedback = ({title, bold}) => {
    const [display, setDisplay] = React.useState(false)

    React.useEffect(() => {
        setDisplay(true)
    }, [])

    return (
        <section id="clients-feedback" className="testimonials-area ptb-100">
            <div className="container">
                <div className="text-center mb-5">
                    <h2 className="h3-title"> {title} <strong>{bold}</strong></h2>
                </div>

                {display ? <OwlCarousel
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                >
                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p> I was surprised by the fast account activation and how easily they solved my situation
                                through the
                                ticketing system! Very satisfied with the quality of SiteBunker’s services!
                            </p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Feraru Adrian</h3>

                                <div className="client-rating">
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                </div>

                                <a className='main-color font-weight-bold' href='https://website-config.com/'
                                   target="_blank">website-config.com</a>
                                <span className='d-block'>Belgium</span>
                            </div>

                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>There are no words to describe SiteBunker’s services. If you are looking for WEB hosting
                                services, at
                                SiteBunker you have available subscriptions designed for any budget. Also you can get a
                                free month to
                                test everything. In conclusion, I recommend SiteBunker without a doubt. You rarely find
                                a technical
                                support like this, 10 stars.</p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Zoltan Torok</h3>
                                <div className="client-rating">
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                </div>
                                <a className='main-color font-weight-bold' href='https://www.absolute-knowledge.com/'
                                   target="_blank">absolute-knowledge.com</a>
                                <span className='d-block'>Ungary</span>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p> The best offers and customer service! Good job!</p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Muneedra Tulve</h3>
                                <div className="client-rating">
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                </div>
                                <a className='main-color font-weight-bold' href='https://www.frozenlogic.net/'
                                   target="_blank">frozenlogic.net</a>
                                <span className='d-block'>India</span>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>The fastest and most efficient technical support on the market. Congratulations!
                            </p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Erik Henning</h3>
                                <div className="client-rating">
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                </div>
                                <a className='main-color font-weight-bold' href='https://digitaloranges.com/'
                                   target="_blank">digitaloranges.com</a>
                                <span className='d-block'>Norway</span>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>They respond almost instantly to tickets, the support is great. I strongly recommend
                                Sitebunker.</p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Mustafa Baaj</h3>
                                <div className="client-rating">
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                </div>
                                <a className='main-color font-weight-bold' href='https://rebina.eu/'
                                   target="_blank">rebina.eu</a>
                                <span className='d-block'>Egypt</span>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>I have been working with Sitenbuker for a few years for more than 8 websites and online
                                stores. They
                                have a really great support. </p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Efe Berk Öztürk</h3>
                                <div className="client-rating">
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                </div>
                                <a className='main-color font-weight-bold' href='https://www.istvanpalko.com/'
                                   target="_blank">istvanpalko.com</a>
                                <span className='d-block'>Turkey</span>

                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>Great hosting service. I have many sites hosted at SiteBunker and they are really worth
                                all the money.
                                They always respond promptly to any ticket.</p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Justin Wolfer</h3>
                                <div className="client-rating">
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                </div>
                                <a className='main-color font-weight-bold' href='https://avminnovation.com/'
                                   target="_blank">avminnovation.com</a>
                                <span className='d-block'>United States</span>
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>Any problem encountered was solved in less than 30 minutes. I highly recommend
                                SiteBunker! </p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Antonov Dmitry</h3>
                                <div className="client-rating">
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                </div>
                                <a className='main-color font-weight-bold' href='https://blackseango.org/'
                                   target="_blank">blackseango.org</a>
                                <span className='d-block'>Russia</span>
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>The best without a doubt! Very prompt, quality services. I recommend SiteBunker !!! </p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Lindsey Hopkins</h3>
                                <div className="client-rating">
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                </div>
                                <a className='main-color font-weight-bold' href='https://cinelight.com/'
                                   target="_blank">cinelight.com</a>
                                <span className='d-block'>United States</span>
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p> The hosting services are exceptional. Everything is very well set up, and usually at any
                                support
                                ticket I receive a response within 5 minutes. I tried many services like this before I
                                got here. I am
                                very pleased!
                            </p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Quentin Gonus</h3>
                                <div className="client-rating">
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                    <i className='bx bxs-star'></i>
                                </div>
                                <a className='main-color font-weight-bold' href='https://2cart.io/'
                                   target="_blank">2cart.io</a>
                                <span className='d-block'>United States</span>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ""}
            </div>
        </section>
    )
}

export default ClientFeedback
